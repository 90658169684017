<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
      id="kt_login"
    >
      <div
        class="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden"
      >
        <div class="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
          <router-view></router-view>
        </div>
      </div>

      <!-- <div
        class="login-aside d-flex p-10 p-lg-15"
        :style="{ backgroundImage: `url(${backgroundImage})` }" 
        style="background-position: center; background-size: cover; min-widht: unset; max-width: unset; width: 50%;"
      >
          <div class="d-flex flex-row-fluid flex-column justify-content-between">
            <a href="#" class="flex-column-auto">
              <img src="https://i.ibb.co/GHhRZ52/gip-logo.png" height="75px" />
            </a>
            
          <div
            class="d-none flex-column-auto d-lg-flex justify-content-between mt-15"
          >
            <div class="opacity-70 font-weight-bold text-white">
              © 2020 Nam Logistik
            </div>
            <div class="d-flex">
              <a href="https://act.id/home/in" class="text-white">act.id</a>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import { mapState } from "vuex";

export default {
  name: "auth",
  methods: {},
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    // backgroundImage() {
    //   // return process.env.BASE_URL + "media/bg/bg-10.jpg";
    //   // return "https://s3.ap-southeast-1.amazonaws.com/prod-act-news/dsc_9335.JPG";
    //   return "https://s3.ap-southeast-1.amazonaws.com/prod-act-news/humanity_store__4_.jpeg";
    // }
  },
};
</script>
